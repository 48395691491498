import { createRouter, createWebHistory } from "vue-router";
import { getEncryptedItem } from "@/utils/encryptedStorage";
import { clearLocalStorageExcept } from "@/utils/storageUtils";

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/",
    name: "home",
    component: () => import("../views/Dashboard/Home.vue"),
    meta: { requiresAuth: true },
  },
  // Análises
  {
    path: "/analises",
    name: "analytics",
    component: () => import("../views/Analytics/Analytics.vue"),
    meta: {
      requiresAuth: true,
      permission: [
        "getOperations",
        "getTelemetry",
        "getDevPoints",
        "getImplPoints",
        "getSupPoints",
        "getCustomerQuery",
        "getCommercialPerformance",
        "getManagement",
        "getClientRevenue",
        "getFinance",
      ],
    },
  },

  // Empresas
  {
    path: "/empresas/:activeTab",
    name: "company",
    component: () => import("../views/Company/CompanySection.vue"),
    props: (route) => ({ activeTab: route.params.activeTab }),
    meta: {
      requiresAuth: true,
      permission: [
        "getCompanies",
        "getCompany",
        "getCompanyGroups",
        "getSegment",
      ],
    },
  },
  {
    path: "/empresas/editar-empresa/:mode/:id",
    name: "editCompany",
    component: () => import("../views/Company/EditCompany.vue"),
    props: true,
    meta: { requiresAuth: true, permission: ["getCompany", "editCompany"] },
  },
  {
    path: "/empresas/criar-empresa/:mode",
    name: "createCompany",
    component: () => import("../views/Company/EditCompany.vue"),
    props: true,
    meta: { requiresAuth: true, permission: "createCompany" },
  },

  // Empresas // Grupos
  {
    path: "/empresas/criar-grupo/:mode",
    name: "createCompanyGroup",
    component: () => import("../views/Company/EditCompanyGroup.vue"),
    props: true,
    meta: { requiresAuth: true, permission: "createCompanyGroup" },
  },
  {
    path: "/empresas/editar-grupo/:mode/:groupId",
    name: "editCompanyGroup",
    component: () => import("../views/Company/EditCompanyGroup.vue"),
    props: true,
    meta: {
      requiresAuth: true,
      permission: ["getCompanyGroup", "editCompanyGroup"],
    },
  },

  // Usuarios
  {
    path: "/usuarios/:activeTab",
    name: "user",
    component: () => import("../views/User/UserSection.vue"),
    props: (route) => ({ activeTab: route.params.activeTab || "0" }),
    meta: {
      requiresAuth: true,
      permission: ["getUser", "getDepartment", "getRoles"],
    },
  },
  {
    path: "/usuarios/criar-usuario/:mode",
    name: "createUser",
    component: () => import("../views/User/EditUser.vue"),
    props: true,
    meta: { requiresAuth: true, permission: "createUser" },
  },
  {
    path: "/usuarios/editar-usuario/:mode/:id",
    name: "editUser",
    component: () => import("../views/User/EditUser.vue"),
    props: true,
    meta: { requiresAuth: true, permission: ["editUser"] },
  },
  {
    path: "/usuarios/editar-perfil",
    name: "userProfile",
    component: () => import("../views/User/UserProfile.vue"),
    meta: { requiresAuth: true },
  },

  // Usuarios // Papeis
  {
    path: "/usuarios/papeis",
    name: "role",
    component: () => import("../views/User/Role.vue"),
    meta: { requiresAuth: true, permission: "getRoles" },
  },
  {
    path: "/usuarios/criar-papel/:mode",
    name: "createRole",
    component: () => import("../views/User/EditRole.vue"),
    props: true,
    meta: { requiresAuth: true, permission: "createRole" },
  },
  {
    path: "/usuarios/editar-papel/:mode/:id",
    name: "editRole",
    component: () => import("../views/User/EditRole.vue"),
    props: true,
    meta: { requiresAuth: true, permission: ["getRole", "editRole"] },
  },

  // Usuarios // Departamentos
  {
    path: "/usuarios/departamentos",
    name: "department",
    component: () => import("../views/User/Department.vue"),
    meta: { requiresAuth: true, permission: "getDepartment" },
  },

  // Produtos
  {
    path: "/produtos/:activeTab",
    name: "products",
    component: () => import("../views/Product/ProductSection.vue"),
    props: (route) => ({ activeTab: route.params.activeTab }),
    meta: {
      requiresAuth: true,
      permission: ["getProduct", "getOpusBkp", "getFluxusMobile"],
    },
  },

  // Ferramentas e Utilitários
  {
    path: "/ferramentas-utilitarios/:selectedType",
    name: "ToolsUtilities",
    component: () =>
      import("../views/ToolsUtilities/ToolsUtilitiesSection.vue"),
    props: (route) => ({ selectedType: route.params.selectedType }),
    meta: {
      requiresAuth: true,
      permission: ["getToolsUtilities"],
    },
  },
  {
    path: "/ferramentas-utilitarios/criar-versao-mobile/:mode",
    name: "createMobileVersion",
    component: () =>
      import("../views/ToolsUtilities/FluxusMobile/EditMobileVersion.vue"),
    props: true,
    meta: { requiresAuth: true, permission: "createToolsUtilities" },
  },
  {
    path: "/ferramentas-utilitarios/editar-versao-mobile/:mode/:idVer",
    name: "editMobileVersion",
    component: () =>
      import("../views/ToolsUtilities/FluxusMobile/EditMobileVersion.vue"),
    props: true,
    meta: { requiresAuth: true, permission: "editToolsUtilities" },
  },
  {
    path: "/ferramentas-utilitarios/criar-servico-fm/:mode",
    name: "createFMService",
    component: () =>
      import("../views/ToolsUtilities/FMService/EditFMService.vue"),
    props: true,
    meta: { requiresAuth: true, permission: "createToolsUtilities" },
  },
  {
    path: "/ferramentas-utilitarios/editar-servico-fm/:mode/:idVer",
    name: "editFMService",
    component: () =>
      import("../views/ToolsUtilities/FMService/EditFMService.vue"),
    props: true,
    meta: { requiresAuth: true, permission: "editToolsUtilities" },
  },
  {
    path: "/ferramentas-utilitarios/criar-instalador-fm/:mode",
    name: "createFMInstaller",
    component: () =>
      import("../views/ToolsUtilities/FMInstaller/EditFMInstaller.vue"),
    props: true,
    meta: { requiresAuth: true, permission: "createToolsUtilities" },
  },
  {
    path: "/ferramentas-utilitarios/editar-instalador-fm/:mode/:idVer",
    name: "editFMInstaller",
    component: () =>
      import("../views/ToolsUtilities/FMInstaller/EditFMInstaller.vue"),
    props: true,
    meta: { requiresAuth: true, permission: "editToolsUtilities" },
  },
  {
    path: "/ferramentas-utilitarios/criar-launcher/:mode",
    name: "createLauncher",
    component: () =>
      import("../views/ToolsUtilities/Launcher/EditLauncher.vue"),
    props: true,
    meta: { requiresAuth: true, permission: "createToolsUtilities" },
  },
  {
    path: "/ferramentas-utilitarios/editar-launcher/:mode/:idVer",
    name: "editLauncher",
    component: () =>
      import("../views/ToolsUtilities/Launcher/EditLauncher.vue"),
    props: true,
    meta: { requiresAuth: true, permission: "editToolsUtilities" },
  },
  {
    path: "/ferramentas-utilitarios/criar-updater/:mode",
    name: "createUpdater",
    component: () => import("../views/ToolsUtilities/Updater/EditUpdater.vue"),
    props: true,
    meta: { requiresAuth: true, permission: "createToolsUtilities" },
  },
  {
    path: "/ferramentas-utilitarios/editar-updater/:mode/:idVer",
    name: "editUpdater",
    component: () => import("../views/ToolsUtilities/Updater/EditUpdater.vue"),
    props: true,
    meta: { requiresAuth: true, permission: "editToolsUtilities" },
  },
  {
    path: "/ferramentas-utilitarios/criar-componentes/:mode",
    name: "createComponent",
    component: () =>
      import(
        "../views/ToolsUtilities/FluxusComponents/EditFluxusComponents.vue"
      ),
    props: true,
    meta: { requiresAuth: true, permission: "createToolsUtilities" },
  },
  {
    path: "/ferramentas-utilitarios/editar-componentes/:mode/:idComponent",
    name: "editComponent",
    component: () =>
      import(
        "../views/ToolsUtilities/FluxusComponents/EditFluxusComponents.vue"
      ),
    props: true,
    meta: { requiresAuth: true, permission: "editToolsUtilities" },
  },
  // Gerenciamento de Arquivos
  {
    path: "/gerenciamento-arquivos/:selectedType",
    name: "filesManagement",
    component: () =>
      import("../views/FilesManagement/FilesManagementSection.vue"),
    props: (route) => ({ selectedType: route.params.selectedType }),
    meta: {
      requiresAuth: true,
      // permission: ["getVersion", "getSlide", "getRevenue"],
      permission: ["getVersion", "getSlide"],
    },
  },
  // Gerenciamento de Arquivos // Versoes
  {
    path: "/gerenciamento-arquivos/criar-versao/:mode",
    name: "createVersion",
    component: () => import("../views/FilesManagement/Fluxus/EditVersion.vue"),
    props: true,
    meta: { requiresAuth: true, permission: "createVersion" },
  },
  {
    path: "/gerenciamento-arquivos/editar-versao/:mode/:idVer/:code/:type/:versionNum",
    name: "editVersion",
    component: () => import("../views/FilesManagement/Fluxus/EditVersion.vue"),
    props: true,
    meta: { requiresAuth: true, permission: "editVersion" },
  },
  // Gerenciamento de Arquivos // Slides
  {
    path: "/gerenciamento-arquivos/criar-slide/:mode",
    name: "createSlide",
    component: () => import("../views/FilesManagement/Slides/EditSlide.vue"),
    props: true,
    meta: { requiresAuth: true, permission: "createSlide" },
  },
  {
    path: "/gerenciamento-arquivos/editar-slide/:mode/:id",
    name: "editSlide",
    component: () => import("../views/FilesManagement/Slides/EditSlide.vue"),
    props: true,
    meta: { requiresAuth: true, permission: "editSlide" },
  },

  // Configurações
  {
    path: "/configuracoes/:activeTab",
    name: "settings",
    component: () => import("../views/Settings.vue"),
    props: (route) => ({ activeTab: route.params.activeTab }),
    meta: { requiresAuth: true, permission: "getSettings" },
  },

  // Chatbot
  {
    path: "/chatbot/:activeTab",
    name: "chatbot",
    component: () => import("../views/Chatbot/ChatbotSection.vue"),
    props: (route) => ({ activeTab: route.params.activeTab }),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/chatbot/editar-artigo/:mode/:id",
    name: "editChatbotArticle",
    component: () => import("../views/Chatbot/EditChatbotArticle.vue"),
    props: true,
    meta: { requiresAuth: true, permission: "editChatbotArticles" },
  },
  {
    path: "/chatbot/criar-artigo/:mode",
    name: "createChatbotArticle",
    component: () => import("../views/Chatbot/EditChatbotArticle.vue"),
    props: true,
    meta: { requiresAuth: true, permission: "createChatbotArticles" },
  },
  // Technical Documentation
  {
    path: "/documentacao",
    name: "docs",
    component: () => import("../views/Docs/Docs.vue"),
    meta: { requiresAuth: true, permission: "getTechnicalDocs" },
  },
  // 404 NotFound
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("../views/NotFound.vue"),
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const isAuthenticated = localStorage.getItem("backoffice_token");

  if (requiresAuth && !isAuthenticated) {
    next({ name: "login" });
  } else if (requiresAuth) {
    const userPermissions = await getEncryptedItem("backoffice_permissions");
    const hasPermission = to.meta.permission
      ? checkUserPermission(to, JSON.parse(userPermissions))
      : true;
    if (!hasPermission) {
      clearLocalStorageExcept();
      next({ name: "login", query: { sessionExpired: true } });
    } else {
      next();
    }
  } else if (isAuthenticated) {
    next({ name: "home" });
  } else {
    next();
  }
});

function checkUserPermission(toRoute, userPermissions) {
  const permissions = toRoute.meta.permission;
  if (Array.isArray(permissions)) {
    return permissions.some(
      (perm) => userPermissions && userPermissions[perm] === true
    );
  } else {
    return userPermissions && userPermissions[permissions] === true;
  }
}

export default router;
