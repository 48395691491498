<template>
  <li :class="customClass">
    <router-link
      v-tooltip="!displayed ? tooltip : ''"
      :to="to"
      class="flex items-center text-dark-blue-2 dark:text-surface-200 stroke-dark-blue-2 dark:stroke-surface-200 rounded-lg h-[40px] w-full"
      :class="{
        'justify-center': !displayed,
        'bg-dark-blue dark:bg-surface-800/50 text-surface-0 stroke-surface-0':
          active,
        'hover:bg-surface-100 dark:hover:bg-surface-800/50': !active,
      }"
      @click="handleClick"
    >
      <div
        :class="[
          'flex items-center ml-[10px] w-full',
          { '!w-auto': isMobile && displayed },
        ]"
      >
        <slot name="icon">
          <i class="ml-1" :class="[icon]"></i>
        </slot>
        <div
          :class="[
            'm-3 relative overflow-hidden',
            { 'opacity-0 hidden': !displayed || isMobile },
          ]"
        >
          <span
            :class="{
              hidden: !displayed || isMobile,
              'whitespace-nowrap': true,
            }"
            >{{ label }}</span
          >
        </div>
      </div>
    </router-link>
  </li>
</template>

<script>
export default {
  props: {
    to: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: true,
    },
    tooltip: {
      type: String,
      required: true,
    },
    displayed: {
      type: Boolean,
      required: true,
    },
    isMobile: {
      type: Boolean,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
    },
    customClass: {
      type: Object,
      required: false,
    },
  },
  methods: {
    handleClick(event) {
      this.$emit("click", event);
    },
  },
};
</script>
